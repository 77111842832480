import { Alert, Button, Form, Input } from "antd";
import useLogin from "./useLogin";
import { formRules } from "./formRules";
import { InfoCircleOutlined, MacCommandOutlined, UpSquareOutlined } from "@ant-design/icons";

const Login = () => {
  const forgetPassowrdURL = process.env.REACT_APP_FORGET_PASSWORD_URL;
  const { handleLogin, loginApiLoading } = useLogin();
  return (
    <Form
      className="h-100 w-100 flex-1"
      layout="vertical"
      onFinish={handleLogin}
    >
      <h3 className="font-bold uppercase login-h">Login</h3>
      <p className="subtle-text text-14 helper-text">
        Please provide your login credentials
      </p>

      <Form.Item
        rules={formRules["username-email"]}
        label="Username or Email"
        name={"username-email"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name={"password"}
        rules={[{ message: "Password is rquired", required: true }]}
      >
        <Input.Password />
      </Form.Item>

      <Button
        type="primary"
        block
        htmlType="submit"
        className="login-btn"
        loading={loginApiLoading}
      >
        Login
      </Button>
      <div className="forgot-passowrd-container">
        <Button type='link' onClick={()=> {window.open(forgetPassowrdURL, "_blank", "noopener,noreferrer");}} className="forget-passowrd-button text-14 helper-text">
          Forgot password?
        </Button>
      </div>
      <div>
          <Alert
          type="info"
          showIcon
          message={
            <div style={{ display: "flex", alignItems: "center" }}>
              Having trouble logging in?
            </div>
          }
          description={
            <div>
            Enable notifications for this site and
            <br/>
            Try refreshing the page by:
            <br/>
            <b>Mac:</b> <code>Command <MacCommandOutlined /> + Shift <UpSquareOutlined /> + R</code>
            <br/>
            <b>Windows:</b> <code>Ctrl + Shift <UpSquareOutlined /> + R</code>
            </div>
          }
          style={{ marginBottom: 16 }}
        />
      </div>
    </Form>
  );
};

export default Login;

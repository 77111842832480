import { message } from "antd";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./config";

export const fetchToken = async () => {
  // First, request permission
  try {
    await requestPermission(messaging);
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    } catch (err) {
      console.log("An error occurred while retrieving token. ", err);
      return null;
    }
  } catch (err_1) {
    console.log("Permission denied: ", err_1);
    return null;
  }
};

export const requestPermission = async () => {
  // Request permission to receive notifications
  const permission = await Notification.requestPermission();

  // If the user granted permission
  if (permission === "granted") {
    console.log("Notification permission granted.");
  } else {
    message.error("Please enable browser notifications.")
    throw new Error("Permission not granted");
  }
};

export const onMessageListener = (callback) => {
  return onMessage(messaging, callback);
};
